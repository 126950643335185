import { baseLocale, Locale } from '@/app/context/LocalizationContext';
import localConfig from '@/config';
import { QueryKeys } from '@/constants/queryKeys';
import { contentfulClient } from '@/utils';
import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';
import { AISectionsSkeleton, AISectionsEntry, EcosystemDappEntry } from '../types';

const fetchAISections = async () => {
  try {
    let content = [];

    if (localConfig.showContentLive) {
      content = (
        await contentfulClient.withAllLocales.getEntries<AISectionsSkeleton, Locale>({
          content_type: 'aiSections',
          include: 10,
        })
      ).items;
    } else {
      content = (
        await axios.get<void, AxiosResponse<AISectionsEntry[]>>(`${localConfig.contentfulUrl}/aiSections.json`)
      ).data;
    }

    const result: Record<string, { title?: string; projects: Record<string, any>[] }> = {};

    content.forEach((section) => {
      if (section.fields.name[baseLocale]) {
        result[section.fields.name[baseLocale]] = {
          title: section.fields?.title?.[baseLocale],
          projects: (section.fields?.projects?.[baseLocale] as unknown as EcosystemDappEntry[])?.reduce(
            (result: Record<string, any>[], entry: EcosystemDappEntry) => {
              if (entry.fields) {
                const {
                  background,
                  category,
                  description,
                  discord,
                  featured,
                  logo,
                  name,
                  state,
                  twitterx,
                  website,
                  aiPage,
                  aiFeatured,
                } = entry.fields;

                result.push({
                  name: name?.[baseLocale],
                  state: state?.[baseLocale],
                  website: website?.[baseLocale],
                  discord: discord?.[baseLocale],
                  twitterx: twitterx?.[baseLocale],
                  description: description?.[baseLocale],
                  background: background?.[baseLocale]?.fields?.file?.[baseLocale]?.url
                    ? `https:${background?.[baseLocale]?.fields?.file?.[baseLocale]?.url}`
                    : '',
                  category: category?.[baseLocale]?.fields?.name?.[baseLocale],
                  featured: featured?.[baseLocale],
                  logo: logo?.[baseLocale]?.fields?.file?.[baseLocale]?.url
                    ? `https:${logo?.[baseLocale]?.fields?.file?.[baseLocale]?.url}`
                    : '',
                  aiPage: aiPage?.[baseLocale],
                  aiFeatured: aiFeatured?.[baseLocale],
                });
              }
              return result;
            },
            []
          ),
        };
      }
    });

    return result;
  } catch (e) {
    console.error(e);
    return;
  }
};

export const useAISections = (options = {}) => {
  return useQuery({
    queryKey: [QueryKeys.AI_SECTIONS],
    queryFn: fetchAISections,
    ...options,
  });
};

export type AIPage = NonNullable<Awaited<ReturnType<typeof fetchAISections>>>;
